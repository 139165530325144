.signup-container {
    width: 100%;
    display: grid;
    height: 700px;
    align-items: center;
    justify-content: center;
}

.signup-main {
    width: 50%;
    margin: 0 auto;
    min-height: 350px;
    border-radius: 5px;
    padding: 25px;
    font-family: var(--font-family);
}

.signup-main h5 {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.signup-main .pleaselog-text {
    color: #4B5976;
    font-size: 13px;
    text-align: center;
    font-weight: 600;
}

.signup-main .phoneinpputfeild {
    margin-top: 0px;
}

@media only screen and (max-width: 768px) {
    .signup-container {
        height: 800px;
    }
    .signup-main {
        width: 80%;
    }
    .signup-main .password {
       margin-top: 10px;
    }
}