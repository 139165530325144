.about-title {
    position: absolute;
    top: 80px;
}

.contact-us-new .section-1 {
    background-image: url('../../assets/img/images/contact-cover-img.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.contact-us-new .section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.contact-us-new .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.contact-us-new .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
}

.contact-us-new .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.contact-us-new h1 {
    font-size: 28px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: #4A4A6B;
    margin-bottom: 10px;
    padding-top: 50px;
    text-align: center;
}

.contact-us-new p {
    color: #4B5976;
    font-size: 15px;
    font-family: 'lato';
    line-height: 25px;
    font-weight: 500;
    margin-bottom: 40px;
    text-align: center;
}

/* section 2 start */

.consection-2 .card {
    margin-top: 50px;
    border: 0;
    background-color: transparent;
    box-shadow: 0px 0px transparent;
    text-align: center;
}

.consection-2 .card .card-title {
    text-align: center;
}

.consection-2 img {
    width: 100px;
    margin: 0 auto;
    height: 100px;
    object-fit: contain;
}

.consection-2 .call-us-main {
    text-align: center;
}

.consection-2 .card .card-text {
    font-size: 15px;
    color: #4B5976;
    font-family: 'Lato', sans-serif;
    text-align: end;
}

.consection-2 .card a {
    text-decoration: none;
    color: var(--main-color);
    padding-left: 10px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.consection-2 .card .phone {
    color: var(--main-color);
    font-size: 23px;
}

.toll-free {
    font-size: 15px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    color: #4B5976;
}

.consection-2 .card small {
    text-align: center;
    width: 80%;
    display: block;
    margin: 0 auto;
    color: #4B5976;
    font-size: .8em;
}

.consection-2 .c-time {
    text-align: start !important;
    padding-left: 10px;
    font-weight: bold;
}

.consection-2 .send-button {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

/* section 2 end */
.consection-3 {
    background-color: #EBF2FF;
}

.consection-3 .selected {
    font-size: 12px;
    padding: 8px;
    color: #4B5976;
}

.con-text-area {
    border-radius: 5px !important;
    box-shadow: 0px 0px 5px -2px grey;
    background-color: #fff;
}

.consection-3 .send-btn {
    width: 200px;
    margin-bottom: 50px;
}

input[type='text'] {
    background-color: #fff;
}

input[type='email'] {
    background-color: #fff;
}

@media only screen and (max-width: 600px) {
    .contact-us-new {
        background-color: #fff;
    }

    .contact-us-new .company {
        margin-top: 8px;
    }
}