.sidebar-main {
    font-family: var(--font-family);
    /* background-color: var(--main-color); */
    width: 270px;
    position: absolute;
    right: 0;
    top: 0;
    padding-left: 10px;
}
.sidebar-main h5 {
    font-weight: 600;
    color: #4B5976;
    margin-bottom: 12px;
}

.sidebar-main p {
    margin-left: 15px;
}

.sidebar-main a {
    text-decoration: none;
    color: #949CAD;
    font-weight: 500;
}

.sidebar-main a:hover {
    color: var(--main-color);
}

.sidebar-main .icon {
    font-size: 21px;
}

.sidebar-main table {
    margin-bottom: 10px;
    margin-left: 10px;
}

.sidebar-main .first-td {
    width: 40px;
}

@media only screen and (max-width: 993px) {
    .body-main {
        grid-template-columns: 12fr !important;
        position : relative;
    }
    .bm-child-1{
        display: none;
    }
}