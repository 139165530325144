@import url('https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap');

:root {
    --main-color: #095CF6;
    --font-family: 'Montserrat', sans-serif;
    --font-lato: 'Lato', sans-serif;
    --main-font-weight: 700;
}

.container{
    max-width: 1200px;
}
/* button */
.button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.upload-button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
    color: var(--main-color);
    background-color: #fff;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

/* button */
/* form */
.form-label {
    color: #4B5976;
    font-weight: 500;
    margin-bottom: 5px;
}

/* form */
/* sidebar start */
.body-main {
    display: grid;
    grid-template-columns: 3.5fr 8.5fr;
    min-height: 729px;
}

.bm-child-1 {
    box-shadow: 5px 0 5px -6px grey;
    position: relative;
}

.bm-child-2 {
    background-color: #EBF2FF;
    position: relative;
}

/* sidebar end */
/* common design */
.common-main {
    max-width: 90%;
    margin-left: 20px;
    font-family: var(--font-family);
    margin-bottom: 30px;
}

.common-main .body {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
}

.common-main h2 {
    background-color: var(--main-color);
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 5px 5px 0px 0px;
    margin-top: 40px;
    margin-bottom: 0;
}

.common-main p {
    color: #4B5976;
    font-family: var(--font-lato);
    line-height: 28px;
}

.toggle-btn {
    display: none;
}

@media only screen and (max-width: 993px) {
    .toggle-btn {
        width: 50px;
        height: 30px;
        background-color: var(--main-color);
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 15;
        border-radius: 0px 20px 20px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .toggle-btn .icon {
        color: #fff;
    }

    .mobile-sidebar .sidebar-main {
        background-color: white;
        left: 0;
        top: 0;
        z-index: 12;
    }
}

@media only screen and (min-width: 993px) {
    .mobile-sidebar {
        display: none;
    }
}

/* common design */
/* ------------------------- */

.home-main {
    font-family: var(--font-family);
}

.section-header {
    font-size: 28px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: #4A4A6B;
    margin-bottom: 10px;
}

.section-p {
    font-size: 15px;
    color: #4B5976;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
}

/* section 1 start */
.section-1 {
    background-color: var(--main-color);
    color: #fff;
    max-height: none;
    padding-top: 85px;
    padding-bottom: 85px;
}

.heading-text {
    font-weight: 700;
    font-size: 2.3rem;
    margin-bottom: 10px;
    margin-top: 50px;
}

.heading-secondary {
    font-size: 18px;
    margin-bottom: 10px;
}

.section-1 button {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
    color: var(--main-color) !important;
    background-color: #fff;
    margin-bottom: 25px;
}

.section-1 button:hover {
    background-color: #fff;
}

.section-1 button:focus {
    box-shadow: 0 0 0 transparent;
    background-color: #fff;
}

.responsiveVid {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.homePageVid {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/* section 1 end */
/* section 2 start */
.section-2 {
    background-color: #fff;
    height: auto;
}

.section-2 .row {
    top: -40px;
}

.section-2 .card {
    border: 0;
    background: white;
}

.section-2 .card-title {
    text-align: center;
    font-weight: var(--main-font-weight);
    color: var(--main-color);
    font-size: 28px;
}

.section-2 .card-subtitle {
    color: #4B5976 !important;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    font-family: 'Lato', sans-serif;
    line-height: 18px;
    padding-bottom: 15px;
}

.section-2 .card-text {
    color: #4B5976 !important;
    font-size: 15px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
}

.section-2 .card a {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
    color: var(--main-color) !important;
}

.section-2 .card a:hover {
    background: var(--main-color);
    color: #fff !important;
}

.section-2 a:focus {
    box-shadow: 0 0 0 transparent;
    background-color: #fff;
    color: var(--main-color) !important;
}

.section-2 a:active {
    border-color: var(--main-color) !important;
    background-color: #fff;
    color: var(--main-color) !important;
}

/* section 2 end */
/* section 3 start */
.section-3 {
    background-color: #fff;
}

.section-3 .img-div {
    background-color: #EEEEEE;
    height: auto;
    border-radius: 0px 5px 5px 0px;
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    display: block;
}

.section-3 h1 {
    font-size: 28px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: #4A4A6B;
}

.section-3 a {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

.section-3 a:hover {
    color: #fff !important;
}

.section-3 a:focus {
    box-shadow: 0 0 0 transparent;
}

.s3-h2 {
    font-size: 22px;
    font-weight: 600;
    color: #4C5A76;
    margin-top: 20px;
    margin-bottom: 10px;
}

.s3-p {
    color: #4B5976;
    font-size: 15px;
    font-family: 'lato';
    line-height: 25px;
    font-weight: 500;
}

/* section 3 end */
/* section 4 start*/
.section-4 {
    background-color: #EBF2FF;
}

.section-4-header {
    text-align: center;
}

.section-4 .card-img-top {
    width: 35%;
    margin: 0px auto;
    object-fit: cover;
}

.s4rating {
    text-align: center;
    margin-bottom: 10px;
}

.s4rating i {
    color: var(--main-color);
    font-size: 25px;
    padding-bottom: 10px;
}

.section-4 .card {
    border: 0;
    background: transparent;
    text-align: center;
    box-shadow: 0px 0px transparent;
}

.section-4 .card-text {
    color: #4B5976;
    font-size: 16px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    line-height: 24px;
    font-weight: 500;
}

.section-4 .card-title {
    color: #4A4A6B;
    font-size: 14px;
    text-align: center;
    font-family: var(--font-family);
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 5px;
}

/* section 4 end*/
/* section 5 start */
.section-5 .card {
    border: 0;
    text-align: center;
    box-shadow: 0px 0px transparent;
}

.section-5 img {
    width: 90px;
    margin: 0 auto;
    height: 90px;
    object-fit: contain;
}

.section-5 .card-title {
    text-align: center;
    font-weight: var(--main-font-weight);
    color: var(--main-color);
    font-size: 19px;
}

.section-5 .card-text {
    color: #4B5976 !important;
    font-size: 16px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    line-height: 24px;
    font-weight: 500;
}

.section-5_2 {
    background-color: #F8FAFD;
}

.section-5_2 .card {
    background: transparent;
    border: 0;
    text-align: center;
    box-shadow: 0px 0px transparent;
}

.section-5_2 img {
    width: 90px;
    margin: 0 auto;
    height: 90px;
    object-fit: contain;
}

.section-5_2 .card-title {
    text-align: center;
    font-weight: var(--main-font-weight);
    color: var(--main-color);
    font-size: 19px;
}

.section-5_2 .card-text {
    color: #4B5976 !important;
    font-size: 13px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    line-height: 22px;
    font-weight: 500;
}

.section-5_2 .card a {
    border: 1px solid var(--main-color);
    font-size: 12px;
    font-weight: 600;
    padding: 10px 30px;
    color: var(--main-color) !important;
}

.section-5_2 .card a:hover {
    background: var(--main-color);
    color: #fff !important;
}

.section-5_2 a:focus {
    box-shadow: 0 0 0 transparent;
    background-color: #fff;
    color: var(--main-color) !important;
}

.section-5_2 a:active {
    border-color: var(--main-color) !important;
    background-color: #fff;
    color: var(--main-color) !important;
}

/* section 5 end */
/* section 6 start */
.section-6 {
    background-color: var(--main-color);
    color: #fff;
}

.section-6 h1 {
    font-weight: bold;
    font-size: 50px;
    margin-bottom: 0;
}

.section-6 p {
    font-weight: 500;
}

/* section 6 end */
/* section 7 start */
.section-7 {
    background-color: #F8FAFD;
}

.section-7 .card {
    border: 0;
    background-color: transparent;
    box-shadow: 0px 0px transparent;
    text-align: center;
    margin-bottom: 0;
}

.section-7 .card .card-title {
    text-align: center;
    font-size: 22px;
    color: #4C5A76;
}

.section-7 img {
    width: 100px;
    margin: 0 auto;
    height: 100px;
    object-fit: contain;
}

.section-7 .call-us-main {
    text-align: center;
}

.section-7 .card .card-text {
    font-size: 14px;
    color: #4B5976;
    font-family: 'Lato', sans-serif;
    text-align: end;
    font-weight: 600;
}

.section-7 .card a {
    text-decoration: none;
    color: var(--main-color);
    padding-left: 10px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.section-7 .card .phone {
    color: var(--main-color);
    font-size: 23px;
}

.statHeader {
  font-size: 50px;
  font-weight: 700;
  color: white;
  margin-bottom: 0;
}

table.contactTable {
    margin: 0 auto;
}

.toll-free {
    font-size: 15px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    color: #4B5976;
}

.section-7 .card small {
    text-align: center;
    width: 80%;
    display: block;
    margin: 0 auto;
    color: #4B5976;
    font-size: .8em;
}

.section-7 .c-time {
    text-align: start !important;
    padding-left: 10px;
    font-weight: bold;
}

.section-7 .send-button {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

/* section 7 end */
/* section 8 start */

.section-8 a {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

/* section 8 end */
/* section 9 start */
.section-9 {
    background-color: #F8FAFD;
}

.section-9 .img-div {
    background-color: #EEEEEE;
    height: 300px;
    border-radius: 0px 5px 5px 0px;
}

.section-9 .first-button {
    font-family: 'lato';
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

.section-9 .second-button {
    font-family: 'lato';
    color: var(--main-color);
    font-weight: bold;
    font-size: 14px;
    background-color: transparent;
}

/* section 9 end */
/* section 10 start */
.section-10 {
    background-color: #fff;
}

.section-10 .img-div {
    background-color: #EEEEEE;
    border-radius: 0px 5px 5px 0px;
}

.section-10 .first-button {
    font-family: 'lato';
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

.section-10 .second-button {
    font-family: 'lato';
    color: var(--main-color);
    font-weight: bold;
    font-size: 14px;
    background: #fff;
}

/* section 10 end */
/* section 11 start */
.section-11 {
    background-color: #EBF2FF;
}

.section-11 .card .card-body {
    text-align: start;
}

.section-11 .card .card-title {
    font-size: 20px;
    font-weight: 600;
    color: #4C5A76;
}

.section-11 .card .card-text {
    color: #4B5976;
    font-size: 15px;
    font-family: 'lato';
    line-height: 25px;
}

.section-11 .card a {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
    color: var(--main-color) !important;
    width: 100%;
}

.section-11 .card a:hover {
    background: var(--main-color);
    color: #fff !important;
}

.section-11 a:focus {
    box-shadow: 0 0 0 transparent;
}
.section-11 a:active {
    border:1px solid var(--main-color) !important;
    color: var(--main-color) !important;
    background-color: var(--main-color);
}
.section-11 .card {
    border: 0;
    box-shadow: 0px 0px 5px 0px grey;
}

.section-11 img {
    height: 90%;
    object-fit: cover;
    width: 100%;
}

.section-11 .card a {
    text-decoration: none;
    color: var(--main-color);
    padding-left: 10px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.section-11 .view-button {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

/* section 11 end */
/*  */
/* responsive design */
@media only screen and (max-width: 768px) {
    .home{
        height: 0;
    }
    .heading-text {
        font-size: 2rem;
        margin-top: 0px;
    }

    .section-1 button {
        font-size: 15px;
        font-weight: 600;
        padding: 7px 25px;
        margin-bottom: 50px;
    }

    .section-2 .card-title {
        font-size: 26px;
    }

    .section-2 .card a {
        font-size: 14px;
    }

    .section-7 {
        height: auto;
    }

    .section-7 .send-button {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 600px) {
    .section-2 {
        height: auto;
        padding: 40px 0px;
    }
    .section-2 .row {
        top: 0px;
    }

    .section-2 .card-title {
        font-size: 22px;
    }

    .section-2 .card a {
        font-size: 12px;
    }

    .section-3 .s3-row2 {
        flex-direction: column-reverse;
    }

    .section-3 .row {
        margin-bottom: 0px;
    }

    .section-8 .row {
        flex-direction: column-reverse;
    }
    .section-5 {
        background-color: #fff;
    }
    .section-8 {
        background-color: #fff;
    }

}
