.headerdiv-main {
    background: rgb(9, 92, 246);
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
    color: #fff;
    font-family: var(--font-family);
    padding-top: 80px;
}

.headerdiv-main h1 {
    font-weight: 800;
}

.headerdiv-main span {
    font-size: 15px;
    text-transform: uppercase;
}

.headerdiv-main .icon {
    margin: 0px 10px;
}