.qMain {
    font-family: 'Montserrat', sans-serif;
    color: #4A4A6B;
  }

  .qLogo {
      width: 100%;
      max-width: 150px;
  }

  .qPaddingTop {
    padding-top: 10px;
  }

  .qPaddingBottom {
    padding-bottom: 10px;
  }

  .qHeading {
      font-weight: 600;
      font-size: 1rem;
      padding-top: 0px;
  }

  .qQuoteInfo {
      margin-bottom: 0;
      padding: 0px;
      font-size: .8rem;
      font-weight: 500;
      text-align: left;
      /* padding-bottom: 15px; */
  }

  .customBorder {
      border: 1px solid #D6E4FF;
      border-radius: 5px;
      padding: 15px;
      margin-bottom: 5px;
      background-color: #fff;
  }

  .row.qPaddingBottom.qInfos {
    padding: 5px;
    /* background-color: #d6e4ff; */
    /* border-radius: 5px; */
}

  .addressCol {
    padding-right: 5px;
    padding-left: 5px;
    overflow-wrap: break-word;
    hyphens: manual;
  }

  .quoteHeader {
      background-color: #dde9ff;
      margin-bottom: 25px;
  }

  .qFooter {
      background-color: #dde9ff;
      padding-top: 25px;
      padding-bottom: 25px;
      margin-top: 25px;
  }

  .qLabel {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      color: #4B5976;
      margin-bottom: 0px;
      padding-bottom: 0px;
  }

  .qAddress {
      font-weight: 600;
      font-size: 14px;
      margin: 0;
  }

  .qHr {
      border-color: #D6E4FF;
  }

  .qMeterImg {
      width: 100%;
      max-width: 250px;
      display: block;
      margin: 0 auto;
  }

  .qLarge {
      font-size: 20px;
      font-weight: 600;
  }

  .qMedium {
      font-size: 14px;
      font-weight: 600;
      font-family: 'Montserrat'!important;
  }

  .qUppercase {
    text-transform: uppercase;
  }

  .qTable {
    border-bottom: 1px solid #b7bdc8;
  }

  .qTable tr:hover {
      background-color: #d6e5ff;
  }

  .qExtraValues {
      margin-bottom: 25px;
  }

  .qExtraValues .col-3 {
      padding: 15px;
      text-align: center;
  }

  .qPricing {
    text-align: right;
  }

  .qPrice {
      font-size: 35px;
      font-weight: 600;
      padding-top: 10px;
  }

  .qPricingInner {
      font-size: 1.25rem;
      font-weight: 500;
  }

  .qTaxes {
      margin: 0;
      padding-bottom: 5px;
      font-weight: 500;
  }
  .qTaxesInner {
      font-size: 1rem;
  }

  .qFooterCompany {
      font-size: 18px;
      margin: 0;
      text-transform: uppercase;
      padding-bottom: 5px;
  }

  .qNotice {
      font-size: 13px;
      color: #787878;
  }

  .qBrand {
      text-transform: uppercase;
      font-weight: 500;
      margin: 0;
      padding: 0px 0px;
      font-size: 1rem;
  }

  .qBrandAddress {
      margin: 0;
      padding-bottom: 5px;
  }

  p.qNote {
      font-family: 'Montserrat';
      color: #515171;
      font-weight: 500;
      font-size: 1rem;
  }

  .qTable td {
      font-weight: 500;
  }

  td.qUppercase {
      font-weight: 600;
  }

  .qTable thead th {
      border-color: #dde2eb;
      background-color: #eff0f2;
      font-weight: 500;
      text-transform: uppercase;
      font-family: 'Lato';
  }

  .qPrice {
      font-size: 1.4rem;
      font-weight: 600;
      padding-top: 15px;
      /* color: #0561ff !important; */
  }

  .qTable {
      border: 1px solid #dde2eb;
  }

  hr.qHr {
      padding: 10px 0px;
      margin: 0;
      border-color: #b7bdc8;
      border-width: 2px;
  }

  .row.qHeader {
    border-bottom: 1px solid #edeff1;
    padding-bottom: 10px;
    margin-bottom: 35px;
}

.row.qLetter {
    font-size: .75rem;
}

p.qCustomerInfo {
    padding-top: 10%;
    font-size: 1.25rem;
}

.row.qLetter div p {
    font-weight: 500;
}

.row.qHeader {
    border-bottom: 1px solid #edeff1;
    padding-bottom: 10px;
    margin-bottom: 35px;
    margin-top: 15px;
}

.row.qCompany {
    border-top: 1px solid #edeff1;
    margin-top: 40px;
}

.row.qCompany .col-12 {
    padding-top: 20px;
}

.qTermsSection {
    padding: 0 11px;
}

  .modal-dialog.modal-lg {
    width: 100%;
    max-width: 950px;
    padding: 0 10px;
  }

  nav.bg-epi-blue.navbar.navbar-expand-lg {
      padding: 5px 20px;
  }


  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {

    .qPaddingTop {
      padding-top: 20px;
    }

    .qPaddingBottom {
      padding-bottom: 20px;
    }

    .qQuoteInfo {
        margin-bottom: 0;
    }

    .qLabel {
        font-size: 14px;
    }

    .qAddress {
        font-weight: 600;
        font-size: 16px;
        margin: 0;
    }

    .qHr {
        border-color: #D6E4FF;
    }

    .qLarge {
        font-size: 22px;
    }

    .qMedium {
        font-size: 16px;
    }

    .qPricing {
      text-align: right;
    }

  }

  @media (max-width: 767px) {

    .customBorder {
        margin-bottom: 20px;
    }

  }
