.vertical-timeline-element-content {
    box-shadow: 0px 0px 5px -1px grey !important;
}

.right-icon {
    position: relative;
    color: #1b1b38 !important;
    right: 64px;
    font-size: 22px;
}

.left-icon {
    position: relative;
    color: #1b1b38 !important;
    left: 65px;
    font-size: 22px;
}

.vertical-timeline-element-icon {
    top: 80px !important;
    background-color: #fff !important;
    color: #4A4A6B !important;
    border: 3px solid #0247C8;
    box-shadow: 0px 0px transparent !important;
}

.vertical-timeline.vertical-timeline--two-columns:before {
    background-color: #D6E4FF;
    width: 10px !important;
    margin-left: -5px !important;
    border-radius: 5px;
}

.timeline-date {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: 700;
}

.timeline-title {
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    margin: 0 !important;

}

.timeline-body {
    text-align: center;
    width: 90%;
    margin: 0 auto;
}

@media only screen and (max-width: 1170px) {
    .vertical-timeline-element-icon {
        top: 0px !important;
    }

    .right-icon {
        display: none;
    }

    .left-icon {
        display: none;
    }
    .vertical-timeline-element-content-arrow{
        border-right: 15px solid  #EBF2FF !important;
    }
}