.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  background-color: #FFFFFF;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 70px;
}

.sideNavBack {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  background-color: #FFFFFF;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 70px;
}

.sidenav a {
  /* padding: 8px 8px 8px 32px; */
  text-decoration: none;
  /* font-size: 17px; */
  font-weight: 600;
  color: #000;
  /* display: block; */
  transition: 0.3s;
}

.sidenav a:hover {
  color: #095CF6;
}

.sidenav .nextbtn {
  position: absolute;
  top: 0;
  right: 25px;
  color: #095CF6;
  font-size: 26px;
  margin-left: 50px;
}

.contentM {
  max-width: 500px !important;
  margin: auto !important;
  background: white;
  padding: 10px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
