#content {
  counter-reset: page; 
}

#pageCounter {
  display: table-footer-group;
}

#pageCounter::before {
  counter-increment: page;
  content: counter(page) ' of ';
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}