/* ------------------------- ------------start*/
@import url('https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap');

:root {
    --main-color: #095CF6;
    --font-family: 'Montserrat', sans-serif;
    --font-lato: 'Lato', sans-serif;
    --main-font-weight: 700;
}
.container{
    max-width: 1140px;
}
/* button */
.button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.upload-button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
    color: var(--main-color);
    background-color: #fff;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

/* button */
/* form */
.form-label {
    color: #4B5976;
    font-weight: 500;
    margin-bottom: 5px;
}

/* form */
/* sidebar start */
.body-main {
    display: grid;
    grid-template-columns: 3.5fr 8.5fr;
    min-height: 729px;
}

.bm-child-1 {
    box-shadow: 5px 0 5px -6px grey;
    position: relative;
}

.bm-child-2 {
    background-color: #EBF2FF;
    position: relative;
}

/* sidebar end */
/* common design */
.common-main {
    max-width: 90%;
    margin-left: 20px;
    font-family: var(--font-family);
    margin-bottom: 30px;
}

.common-main .body {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
}

.common-main h2 {
    background-color: var(--main-color);
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 5px 5px 0px 0px;
    margin-top: 40px;
    margin-bottom: 0;
}

.common-main p {
    color: #4B5976;
    font-family: var(--font-lato);
    line-height: 28px;
}

.toggle-btn {
    display: none;
}

@media only screen and (max-width: 993px) {
    .toggle-btn {
        width: 50px;
        height: 30px;
        background-color: var(--main-color);
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 15;
        border-radius: 0px 20px 20px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    .toggle-btn .icon {
        color: #fff;
    }

    .mobile-sidebar .sidebar-main {
        background-color: white;
        left: 0;
        top: 0;
        z-index: 12;
    }
}

@media only screen and (min-width: 993px) {
    .mobile-sidebar {
        display: none;
    }
}

/* common design */
/* ------------------------- ------------common css end*/
.contact .profile-icon {
    width: 100px;
    height: 100px;
    object-fit: contain;
}
.contact .sales-text {
    font-size: 12px;
    font-weight: bold;
    color: #4B5976;
}
.contact .name-text{
    font-size: 12px;
    font-weight: 800;
    color: #000;
}

.contact .email-text{
    font-size: 15px;
    font-weight: 600;
    color: var(--main-color);
}
.form-control {
    padding: 10px !important;
}
.contact label {
    font-size: 15px;
    font-weight: bold;
    color: #4B5976;
    margin-top: 15px;
    margin-bottom: 5px;
}

input[type="submit"] {
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
}

.inputButton {
    text-decoration: none;
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
    color: #fff;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 5px;
    padding: 10px;

    padding: 10px 30px;
}

/* section 7 start */

.section-7 .card .card-title{
    text-align: center;
}
.section-7 img {
    width: 100px;
    margin: 0 auto;
    height: 100px;
    object-fit: contain;
}
.section-7 .call-us-main{
    text-align: center;
}
.section-7 .card a {
    text-decoration: none;
    color: var(--main-color);
    padding-left: 10px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}
.section-7 .card .phone {
    color: var(--main-color);
    font-size: 20px;
}
.toll-free {
    font-size: 15px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    color: #4B5976;
}
.section-7 .card small{
    text-align: center;
    width: 80%;
    display: block;
    margin: 0 auto;
    color: #4B5976;
    font-size: .8em;
}
.section-7 .c-time {
    text-align: start !important;
    padding-left: 10px;
    font-weight: bold;
}
.section-7 .send-button{
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10,96,255);
    background: linear-gradient(90deg, rgba(10,96,255,1) 7%, rgba(0,65,186,1) 75%);
}
/* section 7 end */

/* copied */
.form-control {
    padding: 10px !important;
    border-radius: 5px;
}
.form-control:focus {
    border: 1px solid var(--main-color);
    box-shadow: none;
    outline: 0 !important;
    color: #2c2c2c;
}
textarea#exampleFormControlTextarea1 {
    border: 1px solid #E3E3E3;
}
/* copied */
