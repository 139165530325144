.glow {
  color: #000;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 0px #fff,
    0 0 2px #e60073,
    0 0 0px #e60073,
    0 0 0px #e60073,
    0 0 0px #e60073,
    0 0 0px #e60073,
    0 0 0px #e60073,
  }

  to {
    text-shadow: 0 0 1px #fff,
    0 0 1px rgb(220 53 69),
    0 0 1px rgb(220 53 69),
    0 0 1px rgb(220 53 69),
    0 0 1px rgb(220 53 69),
    0 0 1px rgb(220 53 69),
    0 0 1px rgb(220 53 69);
  }
}

.liveBox {
    background-color: #0561ff;
    border-style: solid;
    margin: 1px;
    padding: 2px 5px;
    color: white;
    border-radius: 10px;
    border: 2px solid #000e29;
}
