.about-title {
    position: absolute;
    top: 80px;
    z-index: 1;
}

.aboutus-main .section-1 {
    background-image: url('../../assets/img/images/aboutUs-coverpage.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    background-color: transparent;
}

.aboutus-main .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.aboutus-main .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
}

.aboutus-main .cover-title-p {
    color: #fff !important;
    margin: 0;
}


.aboutus-main .section-2 {
    width: 100%;
    height: 100%;
    background-color: #fff;
    margin-bottom: 80px;
}

.about-s2-img{
    height: 250px;
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.EPI-Company-Biography-p {
    margin-top: 30px;
    text-align: start !important;
}

.aboutus-main .section-3 {
    background-color: #EBF2FF;
    width: 100%;
    padding-bottom: 80px;
}

.team-img {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border-radius: 50%;
}

.team-name {
    font-size: 18px;
    font-weight: 700;
    text-align: center !important;
    margin-top: 15px;
    margin-bottom: 5px;
    color: var(--main-color);
}

.team-title {
    font-weight: 500;
}

.aboutus-main .section-4 {
    margin: 50px 0px;
    width: 100%;
    background-color: #fff;
}

.aboutus-main h1 {
    font-size: 28px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: #4A4A6B;
    margin-bottom: 10px;
    padding-top: 50px;
}

.aboutus-main p {
    color: #4B5976;
    font-size: 15px;
    font-family: 'lato';
    line-height: 25px;
    font-weight: 500;
    margin-bottom: 40px;
    text-align: center;
}