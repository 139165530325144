@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:wght@100;200;300;500&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
:root {
    --main-color: #095CF6;
    --font-family: 'Montserrat', sans-serif;
    --font-lato: 'Lato', sans-serif;
    --main-font-weight: 700;
}

.container{
    max-width: 1200px;
}
/* button */
.button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.upload-button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
    color: var(--main-color);
    background-color: #fff;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

/* button */
/* form */
.form-label {
    color: #4B5976;
    font-weight: 500;
    margin-bottom: 5px;
}

/* form */
/* sidebar start */
.body-main {
    display: grid;
    grid-template-columns: 3.5fr 8.5fr;
    min-height: 729px;
}

.bm-child-1 {
    box-shadow: 5px 0 5px -6px grey;
    position: relative;
}

.bm-child-2 {
    background-color: #EBF2FF;
    position: relative;
}

/* sidebar end */
/* common design */
.common-main {
    max-width: 90%;
    margin-left: 20px;
    font-family: var(--font-family);
    margin-bottom: 30px;
}

.common-main .body {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
}

.common-main h2 {
    background-color: var(--main-color);
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 5px 5px 0px 0px;
    margin-top: 40px;
    margin-bottom: 0;
}

.common-main p {
    color: #4B5976;
    font-family: var(--font-lato);
    line-height: 28px;
}

.toggle-btn {
    display: none;
}

@media only screen and (max-width: 993px) {
    .toggle-btn {
        width: 50px;
        height: 30px;
        background-color: var(--main-color);
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 15;
        border-radius: 0px 20px 20px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .toggle-btn .icon {
        color: #fff;
    }

    .mobile-sidebar .sidebar-main {
        background-color: white;
        left: 0;
        top: 0;
        z-index: 12;
    }
}

@media only screen and (min-width: 993px) {
    .mobile-sidebar {
        display: none;
    }
}

/* common design */
/* ------------------------- */

.home-main {
    font-family: var(--font-family);
}

.section-header {
    font-size: 28px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: #4A4A6B;
    margin-bottom: 10px;
}

.section-p {
    font-size: 15px;
    color: #4B5976;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
}

/* section 1 start */
.section-1 {
    background-color: var(--main-color);
    color: #fff;
    max-height: none;
    padding-top: 85px;
    padding-bottom: 85px;
}

.heading-text {
    font-weight: 700;
    font-size: 2.3rem;
    margin-bottom: 10px;
    margin-top: 50px;
}

.heading-secondary {
    font-size: 18px;
    margin-bottom: 10px;
}

.section-1 button {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
    color: var(--main-color) !important;
    background-color: #fff;
    margin-bottom: 25px;
}

.section-1 button:hover {
    background-color: #fff;
}

.section-1 button:focus {
    box-shadow: 0 0 0 transparent;
    background-color: #fff;
}

.responsiveVid {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.homePageVid {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/* section 1 end */
/* section 2 start */
.section-2 {
    background-color: #fff;
    height: auto;
}

.section-2 .row {
    top: -40px;
}

.section-2 .card {
    border: 0;
    background: white;
}

.section-2 .card-title {
    text-align: center;
    font-weight: var(--main-font-weight);
    color: var(--main-color);
    font-size: 28px;
}

.section-2 .card-subtitle {
    color: #4B5976 !important;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    font-family: 'Lato', sans-serif;
    line-height: 18px;
    padding-bottom: 15px;
}

.section-2 .card-text {
    color: #4B5976 !important;
    font-size: 15px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
}

.section-2 .card a {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
    color: var(--main-color) !important;
}

.section-2 .card a:hover {
    background: var(--main-color);
    color: #fff !important;
}

.section-2 a:focus {
    box-shadow: 0 0 0 transparent;
    background-color: #fff;
    color: var(--main-color) !important;
}

.section-2 a:active {
    border-color: var(--main-color) !important;
    background-color: #fff;
    color: var(--main-color) !important;
}

/* section 2 end */
/* section 3 start */
.section-3 {
    background-color: #fff;
}

.section-3 .img-div {
    background-color: #EEEEEE;
    height: auto;
    border-radius: 0px 5px 5px 0px;
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    display: block;
}

.section-3 h1 {
    font-size: 28px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: #4A4A6B;
}

.section-3 a {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

.section-3 a:hover {
    color: #fff !important;
}

.section-3 a:focus {
    box-shadow: 0 0 0 transparent;
}

.s3-h2 {
    font-size: 22px;
    font-weight: 600;
    color: #4C5A76;
    margin-top: 20px;
    margin-bottom: 10px;
}

.s3-p {
    color: #4B5976;
    font-size: 15px;
    font-family: 'lato';
    line-height: 25px;
    font-weight: 500;
}

/* section 3 end */
/* section 4 start*/
.section-4 {
    background-color: #EBF2FF;
}

.section-4-header {
    text-align: center;
}

.section-4 .card-img-top {
    width: 35%;
    margin: 0px auto;
    object-fit: cover;
}

.s4rating {
    text-align: center;
    margin-bottom: 10px;
}

.s4rating i {
    color: var(--main-color);
    font-size: 25px;
    padding-bottom: 10px;
}

.section-4 .card {
    border: 0;
    background: transparent;
    text-align: center;
    box-shadow: 0px 0px transparent;
}

.section-4 .card-text {
    color: #4B5976;
    font-size: 16px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    line-height: 24px;
    font-weight: 500;
}

.section-4 .card-title {
    color: #4A4A6B;
    font-size: 14px;
    text-align: center;
    font-family: var(--font-family);
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 5px;
}

/* section 4 end*/
/* section 5 start */
.section-5 .card {
    border: 0;
    text-align: center;
    box-shadow: 0px 0px transparent;
}

.section-5 img {
    width: 90px;
    margin: 0 auto;
    height: 90px;
    object-fit: contain;
}

.section-5 .card-title {
    text-align: center;
    font-weight: var(--main-font-weight);
    color: var(--main-color);
    font-size: 19px;
}

.section-5 .card-text {
    color: #4B5976 !important;
    font-size: 16px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    line-height: 24px;
    font-weight: 500;
}

.section-5_2 {
    background-color: #F8FAFD;
}

.section-5_2 .card {
    background: transparent;
    border: 0;
    text-align: center;
    box-shadow: 0px 0px transparent;
}

.section-5_2 img {
    width: 90px;
    margin: 0 auto;
    height: 90px;
    object-fit: contain;
}

.section-5_2 .card-title {
    text-align: center;
    font-weight: var(--main-font-weight);
    color: var(--main-color);
    font-size: 19px;
}

.section-5_2 .card-text {
    color: #4B5976 !important;
    font-size: 13px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    line-height: 22px;
    font-weight: 500;
}

.section-5_2 .card a {
    border: 1px solid var(--main-color);
    font-size: 12px;
    font-weight: 600;
    padding: 10px 30px;
    color: var(--main-color) !important;
}

.section-5_2 .card a:hover {
    background: var(--main-color);
    color: #fff !important;
}

.section-5_2 a:focus {
    box-shadow: 0 0 0 transparent;
    background-color: #fff;
    color: var(--main-color) !important;
}

.section-5_2 a:active {
    border-color: var(--main-color) !important;
    background-color: #fff;
    color: var(--main-color) !important;
}

/* section 5 end */
/* section 6 start */
.section-6 {
    background-color: var(--main-color);
    color: #fff;
}

.section-6 h1 {
    font-weight: bold;
    font-size: 50px;
    margin-bottom: 0;
}

.section-6 p {
    font-weight: 500;
}

/* section 6 end */
/* section 7 start */
.section-7 {
    background-color: #F8FAFD;
}

.section-7 .card {
    border: 0;
    background-color: transparent;
    box-shadow: 0px 0px transparent;
    text-align: center;
    margin-bottom: 0;
}

.section-7 .card .card-title {
    text-align: center;
    font-size: 22px;
    color: #4C5A76;
}

.section-7 img {
    width: 100px;
    margin: 0 auto;
    height: 100px;
    object-fit: contain;
}

.section-7 .call-us-main {
    text-align: center;
}

.section-7 .card .card-text {
    font-size: 14px;
    color: #4B5976;
    font-family: 'Lato', sans-serif;
    text-align: end;
    font-weight: 600;
}

.section-7 .card a {
    text-decoration: none;
    color: var(--main-color);
    padding-left: 10px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.section-7 .card .phone {
    color: var(--main-color);
    font-size: 23px;
}

.statHeader {
  font-size: 50px;
  font-weight: 700;
  color: white;
  margin-bottom: 0;
}

table.contactTable {
    margin: 0 auto;
}

.toll-free {
    font-size: 15px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    color: #4B5976;
}

.section-7 .card small {
    text-align: center;
    width: 80%;
    display: block;
    margin: 0 auto;
    color: #4B5976;
    font-size: .8em;
}

.section-7 .c-time {
    text-align: start !important;
    padding-left: 10px;
    font-weight: bold;
}

.section-7 .send-button {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

/* section 7 end */
/* section 8 start */

.section-8 a {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

/* section 8 end */
/* section 9 start */
.section-9 {
    background-color: #F8FAFD;
}

.section-9 .img-div {
    background-color: #EEEEEE;
    height: 300px;
    border-radius: 0px 5px 5px 0px;
}

.section-9 .first-button {
    font-family: 'lato';
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

.section-9 .second-button {
    font-family: 'lato';
    color: var(--main-color);
    font-weight: bold;
    font-size: 14px;
    background-color: transparent;
}

/* section 9 end */
/* section 10 start */
.section-10 {
    background-color: #fff;
}

.section-10 .img-div {
    background-color: #EEEEEE;
    border-radius: 0px 5px 5px 0px;
}

.section-10 .first-button {
    font-family: 'lato';
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

.section-10 .second-button {
    font-family: 'lato';
    color: var(--main-color);
    font-weight: bold;
    font-size: 14px;
    background: #fff;
}

/* section 10 end */
/* section 11 start */
.section-11 {
    background-color: #EBF2FF;
}

.section-11 .card .card-body {
    text-align: start;
}

.section-11 .card .card-title {
    font-size: 20px;
    font-weight: 600;
    color: #4C5A76;
}

.section-11 .card .card-text {
    color: #4B5976;
    font-size: 15px;
    font-family: 'lato';
    line-height: 25px;
}

.section-11 .card a {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
    color: var(--main-color) !important;
    width: 100%;
}

.section-11 .card a:hover {
    background: var(--main-color);
    color: #fff !important;
}

.section-11 a:focus {
    box-shadow: 0 0 0 transparent;
}
.section-11 a:active {
    border:1px solid var(--main-color) !important;
    color: var(--main-color) !important;
    background-color: var(--main-color);
}
.section-11 .card {
    border: 0;
    box-shadow: 0px 0px 5px 0px grey;
}

.section-11 img {
    height: 90%;
    object-fit: cover;
    width: 100%;
}

.section-11 .card a {
    text-decoration: none;
    color: var(--main-color);
    padding-left: 10px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.section-11 .view-button {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

/* section 11 end */
/*  */
/* responsive design */
@media only screen and (max-width: 768px) {
    .home{
        height: 0;
    }
    .heading-text {
        font-size: 2rem;
        margin-top: 0px;
    }

    .section-1 button {
        font-size: 15px;
        font-weight: 600;
        padding: 7px 25px;
        margin-bottom: 50px;
    }

    .section-2 .card-title {
        font-size: 26px;
    }

    .section-2 .card a {
        font-size: 14px;
    }

    .section-7 {
        height: auto;
    }

    .section-7 .send-button {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 600px) {
    .section-2 {
        height: auto;
        padding: 40px 0px;
    }
    .section-2 .row {
        top: 0px;
    }

    .section-2 .card-title {
        font-size: 22px;
    }

    .section-2 .card a {
        font-size: 12px;
    }

    .section-3 .s3-row2 {
        flex-direction: column-reverse;
    }

    .section-3 .row {
        margin-bottom: 0px;
    }

    .section-8 .row {
        flex-direction: column-reverse;
    }
    .section-5 {
        background-color: #fff;
    }
    .section-8 {
        background-color: #fff;
    }

}

.about-title {
    position: absolute;
    top: 80px;
    z-index: 1;
}

.aboutus-main .section-1 {
    background-image: url(/static/media/aboutUs-coverpage.70208523.png);
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    background-color: transparent;
}

.aboutus-main .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.aboutus-main .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
}

.aboutus-main .cover-title-p {
    color: #fff !important;
    margin: 0;
}


.aboutus-main .section-2 {
    width: 100%;
    height: 100%;
    background-color: #fff;
    margin-bottom: 80px;
}

.about-s2-img{
    height: 250px;
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.EPI-Company-Biography-p {
    margin-top: 30px;
    text-align: start !important;
}

.aboutus-main .section-3 {
    background-color: #EBF2FF;
    width: 100%;
    padding-bottom: 80px;
}

.team-img {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border-radius: 50%;
}

.team-name {
    font-size: 18px;
    font-weight: 700;
    text-align: center !important;
    margin-top: 15px;
    margin-bottom: 5px;
    color: var(--main-color);
}

.team-title {
    font-weight: 500;
}

.aboutus-main .section-4 {
    margin: 50px 0px;
    width: 100%;
    background-color: #fff;
}

.aboutus-main h1 {
    font-size: 28px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: #4A4A6B;
    margin-bottom: 10px;
    padding-top: 50px;
}

.aboutus-main p {
    color: #4B5976;
    font-size: 15px;
    font-family: 'lato';
    line-height: 25px;
    font-weight: 500;
    margin-bottom: 40px;
    text-align: center;
}
.vertical-timeline-element-content {
    box-shadow: 0px 0px 5px -1px grey !important;
}

.right-icon {
    position: relative;
    color: #1b1b38 !important;
    right: 64px;
    font-size: 22px;
}

.left-icon {
    position: relative;
    color: #1b1b38 !important;
    left: 65px;
    font-size: 22px;
}

.vertical-timeline-element-icon {
    top: 80px !important;
    background-color: #fff !important;
    color: #4A4A6B !important;
    border: 3px solid #0247C8;
    box-shadow: 0px 0px transparent !important;
}

.vertical-timeline.vertical-timeline--two-columns:before {
    background-color: #D6E4FF;
    width: 10px !important;
    margin-left: -5px !important;
    border-radius: 5px;
}

.timeline-date {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: 700;
}

.timeline-title {
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    margin: 0 !important;

}

.timeline-body {
    text-align: center;
    width: 90%;
    margin: 0 auto;
}

@media only screen and (max-width: 1170px) {
    .vertical-timeline-element-icon {
        top: 0px !important;
    }

    .right-icon {
        display: none;
    }

    .left-icon {
        display: none;
    }
    .vertical-timeline-element-content-arrow{
        border-right: 15px solid  #EBF2FF !important;
    }
}
/* ------------------------- ------------start*/

:root {
    --main-color: #095CF6;
    --font-family: 'Montserrat', sans-serif;
    --font-lato: 'Lato', sans-serif;
    --main-font-weight: 700;
}

.container{
    max-width: 1140px;
}

/* button */
.button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.upload-button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
    color: var(--main-color);
    background-color: #fff;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

/* button */
/* form */
.form-label {
    color: #4B5976;
    font-weight: 500;
    margin-bottom: 5px;
}

/* form */
/* sidebar start */
.body-main {
    display: grid;
    grid-template-columns: 3.5fr 8.5fr;
    min-height: 729px;
}

.bm-child-1 {
    box-shadow: 5px 0 5px -6px grey;
    position: relative;
}

.bm-child-2 {
    background-color: #EBF2FF;
    position: relative;
}

/* sidebar end */
/* common design */
.common-main {
    max-width: 90%;
    margin-left: 20px;
    font-family: var(--font-family);
    margin-bottom: 30px;
}

.common-main .body {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
}

.common-main h2 {
    background-color: var(--main-color);
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 5px 5px 0px 0px;
    margin-top: 40px;
    margin-bottom: 0;
}

.common-main p {
    color: #4B5976;
    font-family: var(--font-lato);
    line-height: 28px;
}

.toggle-btn {
    display: none;
}

@media only screen and (max-width: 993px) {
    .toggle-btn {
        width: 50px;
        height: 30px;
        background-color: var(--main-color);
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 15;
        border-radius: 0px 20px 20px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    .toggle-btn .icon {
        color: #fff;
    }

    .mobile-sidebar .sidebar-main {
        background-color: white;
        left: 0;
        top: 0;
        z-index: 12;
    }
}

@media only screen and (min-width: 993px) {
    .mobile-sidebar {
        display: none;
    }
}

/* common design */
/* ------------------------- ------------common css end*/
/* copied */
.form-control {
    padding: 10px !important;
    border-radius: 5px;
}
.form-control:focus {
    border: 1px solid var(--main-color);
    box-shadow: none;
    outline: 0 !important;
    color: #2c2c2c;
}
textarea#exampleFormControlTextarea1 {
    border: 1px solid #E3E3E3;
}
/* copied */
.login-container{
    width: 100%;
    display: grid;
    height: 700px;
    align-items: center;
    justify-content: center;
}
.login-main {
    width: 350px;
    min-height: 350px;
    border-radius: 5px;
    padding: 25px;
    font-family: var(--font-family);
}

input[type="submit"] {
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    border: 0 !important;
    border-radius: 5px;
    padding: 10px;
}

.login-main h5 {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.login-main .pleaselog-text {
    color: #4B5976;
    font-size: 13px;
    text-align: center;
    font-weight: 600;
}

.forgot-text {
    color: var(--main-color);
    font-size: 13px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 0;
}

.account-text {
    color: var(--main-color);
    font-size: 13px;
    font-weight: 600;
    text-align: center;
}

.sign-up-text {
    text-decoration: none;
}

.login-main .form-control {
    padding: 10px;
}

.login-main .form-control::placeholder {
    color: #949CAD;
    font-size: 15px;
    font-weight: 500;
}

.inputDiv {
  position: relative;
  height: 44px;
  margin-bottom: 1rem;
}

.inputIcon{
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: #222222;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
}
.signup-container {
    width: 100%;
    display: grid;
    height: 700px;
    align-items: center;
    justify-content: center;
}

.signup-main {
    width: 50%;
    margin: 0 auto;
    min-height: 350px;
    border-radius: 5px;
    padding: 25px;
    font-family: var(--font-family);
}

.signup-main h5 {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.signup-main .pleaselog-text {
    color: #4B5976;
    font-size: 13px;
    text-align: center;
    font-weight: 600;
}

.signup-main .phoneinpputfeild {
    margin-top: 0px;
}

@media only screen and (max-width: 768px) {
    .signup-container {
        height: 800px;
    }
    .signup-main {
        width: 80%;
    }
    .signup-main .password {
       margin-top: 10px;
    }
}
.about-title {
    position: absolute;
    top: 80px;
}

.contact-us-new .section-1 {
    background-image: url(/static/media/contact-cover-img.c8793c21.png);
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.contact-us-new .section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.contact-us-new .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.contact-us-new .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
}

.contact-us-new .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.contact-us-new h1 {
    font-size: 28px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: #4A4A6B;
    margin-bottom: 10px;
    padding-top: 50px;
    text-align: center;
}

.contact-us-new p {
    color: #4B5976;
    font-size: 15px;
    font-family: 'lato';
    line-height: 25px;
    font-weight: 500;
    margin-bottom: 40px;
    text-align: center;
}

/* section 2 start */

.consection-2 .card {
    margin-top: 50px;
    border: 0;
    background-color: transparent;
    box-shadow: 0px 0px transparent;
    text-align: center;
}

.consection-2 .card .card-title {
    text-align: center;
}

.consection-2 img {
    width: 100px;
    margin: 0 auto;
    height: 100px;
    object-fit: contain;
}

.consection-2 .call-us-main {
    text-align: center;
}

.consection-2 .card .card-text {
    font-size: 15px;
    color: #4B5976;
    font-family: 'Lato', sans-serif;
    text-align: end;
}

.consection-2 .card a {
    text-decoration: none;
    color: var(--main-color);
    padding-left: 10px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.consection-2 .card .phone {
    color: var(--main-color);
    font-size: 23px;
}

.toll-free {
    font-size: 15px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    color: #4B5976;
}

.consection-2 .card small {
    text-align: center;
    width: 80%;
    display: block;
    margin: 0 auto;
    color: #4B5976;
    font-size: .8em;
}

.consection-2 .c-time {
    text-align: start !important;
    padding-left: 10px;
    font-weight: bold;
}

.consection-2 .send-button {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

/* section 2 end */
.consection-3 {
    background-color: #EBF2FF;
}

.consection-3 .selected {
    font-size: 12px;
    padding: 8px;
    color: #4B5976;
}

.con-text-area {
    border-radius: 5px !important;
    box-shadow: 0px 0px 5px -2px grey;
    background-color: #fff;
}

.consection-3 .send-btn {
    width: 200px;
    margin-bottom: 50px;
}

input[type='text'] {
    background-color: #fff;
}

input[type='email'] {
    background-color: #fff;
}

@media only screen and (max-width: 600px) {
    .contact-us-new {
        background-color: #fff;
    }

    .contact-us-new .company {
        margin-top: 8px;
    }
}
.headerdiv-main {
    background: rgb(9, 92, 246);
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
    color: #fff;
    font-family: var(--font-family);
    padding-top: 80px;
}

.headerdiv-main h1 {
    font-weight: 800;
}

.headerdiv-main span {
    font-size: 15px;
    text-transform: uppercase;
}

.headerdiv-main .icon {
    margin: 0px 10px;
}
.sidebar-main {
    font-family: var(--font-family);
    /* background-color: var(--main-color); */
    width: 270px;
    position: absolute;
    right: 0;
    top: 0;
    padding-left: 10px;
}
.sidebar-main h5 {
    font-weight: 600;
    color: #4B5976;
    margin-bottom: 12px;
}

.sidebar-main p {
    margin-left: 15px;
}

.sidebar-main a {
    text-decoration: none;
    color: #949CAD;
    font-weight: 500;
}

.sidebar-main a:hover {
    color: var(--main-color);
}

.sidebar-main .icon {
    font-size: 21px;
}

.sidebar-main table {
    margin-bottom: 10px;
    margin-left: 10px;
}

.sidebar-main .first-td {
    width: 40px;
}

@media only screen and (max-width: 993px) {
    .body-main {
        grid-template-columns: 12fr !important;
        position : relative;
    }
    .bm-child-1{
        display: none;
    }
}
/* ------------------------- ------------start*/

:root {
    --main-color: #095CF6;
    --font-family: 'Montserrat', sans-serif;
    --font-lato: 'Lato', sans-serif;
    --main-font-weight: 700;
}
.container{
    max-width: 1140px;
}
/* button */
.button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.upload-button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
    color: var(--main-color);
    background-color: #fff;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

/* button */
/* form */
.form-label {
    color: #4B5976;
    font-weight: 500;
    margin-bottom: 5px;
}

/* form */
/* sidebar start */
.body-main {
    display: grid;
    grid-template-columns: 3.5fr 8.5fr;
    min-height: 729px;
}

.bm-child-1 {
    box-shadow: 5px 0 5px -6px grey;
    position: relative;
}

.bm-child-2 {
    background-color: #EBF2FF;
    position: relative;
}

/* sidebar end */
/* common design */
.common-main {
    max-width: 90%;
    margin-left: 20px;
    font-family: var(--font-family);
    margin-bottom: 30px;
}

.common-main .body {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
}

.common-main h2 {
    background-color: var(--main-color);
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 5px 5px 0px 0px;
    margin-top: 40px;
    margin-bottom: 0;
}

.common-main p {
    color: #4B5976;
    font-family: var(--font-lato);
    line-height: 28px;
}

.toggle-btn {
    display: none;
}

@media only screen and (max-width: 993px) {
    .toggle-btn {
        width: 50px;
        height: 30px;
        background-color: var(--main-color);
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 15;
        border-radius: 0px 20px 20px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    .toggle-btn .icon {
        color: #fff;
    }

    .mobile-sidebar .sidebar-main {
        background-color: white;
        left: 0;
        top: 0;
        z-index: 12;
    }
}

@media only screen and (min-width: 993px) {
    .mobile-sidebar {
        display: none;
    }
}

/* common design */
/* ------------------------- ------------common css end*/
/* copied */
.form-control {
    padding: 10px !important;
    border-radius: 5px;
}
.form-control:focus {
    border: 1px solid var(--main-color);
    box-shadow: none;
    outline: 0 !important;
    color: #2c2c2c;
}
textarea#exampleFormControlTextarea1 {
    border: 1px solid #E3E3E3;
}
/* copied */
.newproduct label {
    font-weight: 500;
    color: #4B5976;
    margin-bottom: 10px;
    margin-top: 15px;
}

.new-input-field {
    margin-top: 10px;
}

.check-main input {
    font-size: 30px;
}

.check-main label {
    color: #4B5976;
    margin-top: 10px;
    margin-left: 20px;
    font-weight: 500;
    font-size: 15px;
}

input[type="submit"] {
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
    .newproduct .form-control {
        width: 100%;
    }
}
/* ------------------------- ------------start*/

:root {
    --main-color: #095CF6;
    --font-family: 'Montserrat', sans-serif;
    --font-lato: 'Lato', sans-serif;
    --main-font-weight: 700;
}
.container{
    max-width: 1140px;
}
/* button */
.button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.upload-button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
    color: var(--main-color);
    background-color: #fff;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

/* button */
/* form */
.form-label {
    color: #4B5976;
    font-weight: 500;
    margin-bottom: 5px;
}

/* form */
/* sidebar start */
.body-main {
    display: grid;
    grid-template-columns: 3.5fr 8.5fr;
    min-height: 729px;
}

.bm-child-1 {
    box-shadow: 5px 0 5px -6px grey;
    position: relative;
}

.bm-child-2 {
    background-color: #EBF2FF;
    position: relative;
}

/* sidebar end */
/* common design */
.common-main {
    max-width: 90%;
    margin-left: 20px;
    font-family: var(--font-family);
    margin-bottom: 30px;
}

.common-main .body {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
}

.common-main h2 {
    background-color: var(--main-color);
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 5px 5px 0px 0px;
    margin-top: 40px;
    margin-bottom: 0;
}

.common-main p {
    color: #4B5976;
    font-family: var(--font-lato);
    line-height: 28px;
}

.toggle-btn {
    display: none;
}

@media only screen and (max-width: 993px) {
    .toggle-btn {
        width: 50px;
        height: 30px;
        background-color: var(--main-color);
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 15;
        border-radius: 0px 20px 20px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    .toggle-btn .icon {
        color: #fff;
    }

    .mobile-sidebar .sidebar-main {
        background-color: white;
        left: 0;
        top: 0;
        z-index: 12;
    }
}

@media only screen and (min-width: 993px) {
    .mobile-sidebar {
        display: none;
    }
}

/* common design */
/* ------------------------- ------------common css end*/
.quotesLink {
    text-decoration: none;
    color: var(--main-color);
    font-weight: 600;
    margin-left: 15px;
}

.quotesButtons {
    text-decoration: none;
    font-weight: 600;
}
/* ------------------------- ------------start*/

:root {
    --main-color: #095CF6;
    --font-family: 'Montserrat', sans-serif;
    --font-lato: 'Lato', sans-serif;
    --main-font-weight: 700;
}

.container{
    max-width: 1140px;
}

/* button */
.button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.upload-button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
    color: var(--main-color);
    background-color: #fff;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

/* button */
/* form */
.form-label {
    color: #4B5976;
    font-weight: 500;
    margin-bottom: 5px;
}

/* form */
/* sidebar start */
.body-main {
    display: grid;
    grid-template-columns: 3.5fr 8.5fr;
    min-height: 729px;
}

.bm-child-1 {
    box-shadow: 5px 0 5px -6px grey;
    position: relative;
}

.bm-child-2 {
    background-color: #EBF2FF;
    position: relative;
}

/* sidebar end */
/* common design */
.common-main {
    max-width: 90%;
    margin-left: 20px;
    font-family: var(--font-family);
    margin-bottom: 30px;
}

.common-main .body {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
}

.common-main h2 {
    background-color: var(--main-color);
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 5px 5px 0px 0px;
    margin-top: 40px;
    margin-bottom: 0;
}

.common-main p {
    color: #4B5976;
    font-family: var(--font-lato);
    line-height: 28px;
}

.toggle-btn {
    display: none;
}

@media only screen and (max-width: 993px) {
    .toggle-btn {
        width: 50px;
        height: 30px;
        background-color: var(--main-color);
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 15;
        border-radius: 0px 20px 20px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    .toggle-btn .icon {
        color: #fff;
    }

    .mobile-sidebar .sidebar-main {
        background-color: white;
        left: 0;
        top: 0;
        z-index: 12;
    }
}

@media only screen and (min-width: 993px) {
    .mobile-sidebar {
        display: none;
    }
}

/* common design */
/* ------------------------- ------------common css end*/
.bdr h4 {
    color: #4A4A6B;
    font-weight: 600;
}

.bdr h5 {
    color: #4A4A6B;
    font-weight: 500;
    font-size: 16px;
    /* font-family: var(--font-lato); */
}

.bdr .form-group {
    margin-top: 15px;
}

.profileiconimg {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
}

/* copied */
.form-control {
    padding: 10px !important;
    border-radius: 5px;
}

.form-control:focus {
    border: 1px solid var(--main-color);
    box-shadow: none;
    outline: 0 !important;
    color: #2c2c2c;
}

textarea#exampleFormControlTextarea1 {
    border: 1px solid #E3E3E3;
}

/* copied */
/*  */
.form-control {
    padding: 10px !important;
}

.form-group label {
    color: #4B5976;
    font-weight: 500;
    margin-bottom: 5px;
}

.form-label {
    color: #4B5976;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 10px;
}


.phoneinpputfeild {
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.PhoneInputInput {
    border: 0;
    padding: 10px;
    border-radius: 5px;
}

.PhoneInputInput:focus-visible {
    outline: 0px;
}

.PhoneInputCountryIcon--border {
    margin-left: 10px;
}

input[type="submit"] {
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* editacount start */
.check-main input {
    font-size: 30px;
}

.check-main label {
    color: #4B5976;
    margin-top: 10px;
    margin-left: 20px;
    font-weight: 500;
    font-size: 15px;
}

.Address-btn {
    background: #fff !important;
    border: 2px solid var(--main-color);
    color: var(--main-color) !important;
}

/* .form-check {
    margin-left: 20px !important;
    font-weight: 600;
    color: #4B5976;
} */

.form-check:first-child {
    margin-left: 0px;
}

.n-input-field {
    width: 50% !important;
}

/* .form-check .form-check-label {
    padding-left: 0px;
    padding-right: 10px;
} */

input[type="submit"] {
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
    .n-input-field {
        width: 100% !important;
    }
}

@media only screen and (max-width: 600px) {
    .biliingmobile-col {
        margin-top: 15px !important;
    }
}
/* ------------------------- ------------start*/

:root {
    --main-color: #095CF6;
    --font-family: 'Montserrat', sans-serif;
    --font-lato: 'Lato', sans-serif;
    --main-font-weight: 700;
}
.container{
    max-width: 1140px;
}

/* button */
.button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.upload-button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
    color: var(--main-color);
    background-color: #fff;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

/* button */
/* form */
.form-label {
    color: #4B5976;
    font-weight: 500;
    margin-bottom: 5px;
}

/* form */
/* sidebar start */
.body-main {
    display: grid;
    grid-template-columns: 3.5fr 8.5fr;
    min-height: 729px;
}

.bm-child-1 {
    box-shadow: 5px 0 5px -6px grey;
    position: relative;
}

.bm-child-2 {
    background-color: #EBF2FF;
    position: relative;
}

/* sidebar end */
/* common design */
.common-main {
    max-width: 90%;
    margin-left: 20px;
    font-family: var(--font-family);
    margin-bottom: 30px;
}

.common-main .body {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
}

.common-main h2 {
    background-color: var(--main-color);
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 5px 5px 0px 0px;
    margin-top: 40px;
    margin-bottom: 0;
}

.common-main p {
    color: #4B5976;
    font-family: var(--font-lato);
    line-height: 28px;
}

.toggle-btn {
    display: none;
}

@media only screen and (max-width: 993px) {
    .toggle-btn {
        width: 50px;
        height: 30px;
        background-color: var(--main-color);
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 15;
        border-radius: 0px 20px 20px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    .toggle-btn .icon {
        color: #fff;
    }

    .mobile-sidebar .sidebar-main {
        background-color: white;
        left: 0;
        top: 0;
        z-index: 12;
    }
}

@media only screen and (min-width: 993px) {
    .mobile-sidebar {
        display: none;
    }
}

/* common design */
/* ------------------------- ------------common css end*/
.bdr h4 {
    color: #4A4A6B;
    font-weight: 600;
}
.bdr h5 {
    color: #4A4A6B;
    font-weight: 500;
    font-size: 16px;
    /* font-family: var(--font-lato); */
}
.bdr .form-group {
    margin-top:15px;
}
.profileiconimg {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
}

/* copied */
.form-control {
    padding: 10px !important;
    border-radius: 5px;
}
.form-control:focus {
    border: 1px solid var(--main-color);
    box-shadow: none;
    outline: 0 !important;
    color: #2c2c2c;
}
textarea#exampleFormControlTextarea1 {
    border: 1px solid #E3E3E3;
}
/* copied */
.form-group label {
    color: #4B5976;
    font-weight: 500;
    margin-bottom: 5px;
}
.form-label {
    color: #4B5976;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 10px;
}


.phoneinpputfeild {
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.PhoneInputInput {
    border: 0;
    padding: 10px;
    border-radius: 5px;
}

.PhoneInputInput:focus-visible {
    outline: 0px;
}

.PhoneInputCountryIcon--border {
    margin-left: 10px;
}

input[type="submit"] {
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* ------------------------- ------------start*/

:root {
    --main-color: #095CF6;
    --font-family: 'Montserrat', sans-serif;
    --font-lato: 'Lato', sans-serif;
    --main-font-weight: 700;
}
.container{
    max-width: 1140px;
}
/* button */
.button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.upload-button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
    color: var(--main-color);
    background-color: #fff;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

/* button */
/* form */
.form-label {
    color: #4B5976;
    font-weight: 500;
    margin-bottom: 5px;
}

/* form */
/* sidebar start */
.body-main {
    display: grid;
    grid-template-columns: 3.5fr 8.5fr;
    min-height: 729px;
}

.bm-child-1 {
    box-shadow: 5px 0 5px -6px grey;
    position: relative;
}

.bm-child-2 {
    background-color: #EBF2FF;
    position: relative;
}

/* sidebar end */
/* common design */
.common-main {
    max-width: 90%;
    margin-left: 20px;
    font-family: var(--font-family);
    margin-bottom: 30px;
}

.common-main .body {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
}

.common-main h2 {
    background-color: var(--main-color);
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 5px 5px 0px 0px;
    margin-top: 40px;
    margin-bottom: 0;
}

.common-main p {
    color: #4B5976;
    font-family: var(--font-lato);
    line-height: 28px;
}

.toggle-btn {
    display: none;
}

@media only screen and (max-width: 993px) {
    .toggle-btn {
        width: 50px;
        height: 30px;
        background-color: var(--main-color);
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 15;
        border-radius: 0px 20px 20px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    .toggle-btn .icon {
        color: #fff;
    }

    .mobile-sidebar .sidebar-main {
        background-color: white;
        left: 0;
        top: 0;
        z-index: 12;
    }
}

@media only screen and (min-width: 993px) {
    .mobile-sidebar {
        display: none;
    }
}

/* common design */
/* ------------------------- ------------common css end*/
.contact .profile-icon {
    width: 100px;
    height: 100px;
    object-fit: contain;
}
.contact .sales-text {
    font-size: 12px;
    font-weight: bold;
    color: #4B5976;
}
.contact .name-text{
    font-size: 12px;
    font-weight: 800;
    color: #000;
}

.contact .email-text{
    font-size: 15px;
    font-weight: 600;
    color: var(--main-color);
}
.form-control {
    padding: 10px !important;
}
.contact label {
    font-size: 15px;
    font-weight: bold;
    color: #4B5976;
    margin-top: 15px;
    margin-bottom: 5px;
}

input[type="submit"] {
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
}

.inputButton {
    text-decoration: none;
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
    color: #fff;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 5px;
    padding: 10px;

    padding: 10px 30px;
}

/* section 7 start */

.section-7 .card .card-title{
    text-align: center;
}
.section-7 img {
    width: 100px;
    margin: 0 auto;
    height: 100px;
    object-fit: contain;
}
.section-7 .call-us-main{
    text-align: center;
}
.section-7 .card a {
    text-decoration: none;
    color: var(--main-color);
    padding-left: 10px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}
.section-7 .card .phone {
    color: var(--main-color);
    font-size: 20px;
}
.toll-free {
    font-size: 15px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    color: #4B5976;
}
.section-7 .card small{
    text-align: center;
    width: 80%;
    display: block;
    margin: 0 auto;
    color: #4B5976;
    font-size: .8em;
}
.section-7 .c-time {
    text-align: start !important;
    padding-left: 10px;
    font-weight: bold;
}
.section-7 .send-button{
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10,96,255);
    background: linear-gradient(90deg, rgba(10,96,255,1) 7%, rgba(0,65,186,1) 75%);
}
/* section 7 end */

/* copied */
.form-control {
    padding: 10px !important;
    border-radius: 5px;
}
.form-control:focus {
    border: 1px solid var(--main-color);
    box-shadow: none;
    outline: 0 !important;
    color: #2c2c2c;
}
textarea#exampleFormControlTextarea1 {
    border: 1px solid #E3E3E3;
}
/* copied */

/* ------------------------- ------------start*/

:root {
    --main-color: #095CF6;
    --font-family: 'Montserrat', sans-serif;
    --font-lato: 'Lato', sans-serif;
    --main-font-weight: 700;
}
.container{
    max-width: 1140px;
}
/* button */
.button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.upload-button {
    text-decoration: none;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
    color: var(--main-color);
    background-color: #fff;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

/* button */
/* form */
.form-label {
    color: #4B5976;
    font-weight: 500;
    margin-bottom: 5px;
}

/* form */
/* sidebar start */
.body-main {
    display: grid;
    grid-template-columns: 3.5fr 8.5fr;
    min-height: 729px;
}

.bm-child-1 {
    box-shadow: 5px 0 5px -6px grey;
    position: relative;
}

.bm-child-2 {
    background-color: #EBF2FF;
    position: relative;
    min-height: 1200px;
}

/* sidebar end */
/* common design */
.common-main {
    max-width: 90%;
    margin-left: 20px;
    font-family: var(--font-family);
    margin-bottom: 30px;
}

.common-main .body {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
}

.common-main h2 {
    background-color: var(--main-color);
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 5px 5px 0px 0px;
    margin-top: 40px;
    margin-bottom: 0;
}

.common-main p {
    color: #4B5976;
    font-family: var(--font-lato);
    line-height: 28px;
}

.toggle-btn {
    display: none;
}

@media only screen and (max-width: 993px) {
    .toggle-btn {
        width: 50px;
        height: 30px;
        background-color: var(--main-color);
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 15;
        border-radius: 0px 20px 20px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    .toggle-btn .icon {
        color: #fff;
    }

    .mobile-sidebar .sidebar-main {
        background-color: white;
        left: 0;
        top: 0;
        z-index: 12;
    }
}

@media only screen and (min-width: 993px) {
    .mobile-sidebar {
        display: none;
    }
}

/* common design */
/* ------------------------- ------------common css end*/
.apayment .card-main {
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-row-gap: 10px;
}

.card-main .mcard {
    display: grid;
    grid-template-columns: 2.5fr 9.5fr;
}

.card-main .mcard div:nth-of-type(1) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.mcard b {
    font-weight: 700;
}

.card-main .mcard img {
    width: 50px !important;
    object-fit: cover;
}

.card-main .mcard h4 {
    font-size: 22px;
    font-weight: 600;
    color: #4A4A6B;
    margin-top: 5px;
}

@media only screen and (max-width: 600px) {
    .apayment .card-main {
        grid-template-columns: 12fr;
    }
}

.service-request{
    width: 300px;
}
.service-request .text{
    font-family: var(--font-family);
    text-align: center;
    font-weight: 500;
}
.warranty p{
    font-family: var(--font-family);
    font-weight: 400;
}
.privacypolicy p{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 15px;
}
.deliverytime p{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 15px;
}
.deliverytime p{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 15px;
}
.pm-img {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    object-fit: cover;
}

.pw-card-img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.test-img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 50px;
    object-fit: cover;
}
.qMain {
    font-family: 'Montserrat', sans-serif;
    color: #4A4A6B;
  }

  .qLogo {
      width: 100%;
      max-width: 150px;
  }

  .qPaddingTop {
    padding-top: 10px;
  }

  .qPaddingBottom {
    padding-bottom: 10px;
  }

  .qHeading {
      font-weight: 600;
      font-size: 1rem;
      padding-top: 0px;
  }

  .qQuoteInfo {
      margin-bottom: 0;
      padding: 0px;
      font-size: .8rem;
      font-weight: 500;
      text-align: left;
      /* padding-bottom: 15px; */
  }

  .customBorder {
      border: 1px solid #D6E4FF;
      border-radius: 5px;
      padding: 15px;
      margin-bottom: 5px;
      background-color: #fff;
  }

  .row.qPaddingBottom.qInfos {
    padding: 5px;
    /* background-color: #d6e4ff; */
    /* border-radius: 5px; */
}

  .addressCol {
    padding-right: 5px;
    padding-left: 5px;
    overflow-wrap: break-word;
    -webkit-hyphens: manual;
            hyphens: manual;
  }

  .quoteHeader {
      background-color: #dde9ff;
      margin-bottom: 25px;
  }

  .qFooter {
      background-color: #dde9ff;
      padding-top: 25px;
      padding-bottom: 25px;
      margin-top: 25px;
  }

  .qLabel {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      color: #4B5976;
      margin-bottom: 0px;
      padding-bottom: 0px;
  }

  .qAddress {
      font-weight: 600;
      font-size: 14px;
      margin: 0;
  }

  .qHr {
      border-color: #D6E4FF;
  }

  .qMeterImg {
      width: 100%;
      max-width: 250px;
      display: block;
      margin: 0 auto;
  }

  .qLarge {
      font-size: 20px;
      font-weight: 600;
  }

  .qMedium {
      font-size: 14px;
      font-weight: 600;
      font-family: 'Montserrat'!important;
  }

  .qUppercase {
    text-transform: uppercase;
  }

  .qTable {
    border-bottom: 1px solid #b7bdc8;
  }

  .qTable tr:hover {
      background-color: #d6e5ff;
  }

  .qExtraValues {
      margin-bottom: 25px;
  }

  .qExtraValues .col-3 {
      padding: 15px;
      text-align: center;
  }

  .qPricing {
    text-align: right;
  }

  .qPrice {
      font-size: 35px;
      font-weight: 600;
      padding-top: 10px;
  }

  .qPricingInner {
      font-size: 1.25rem;
      font-weight: 500;
  }

  .qTaxes {
      margin: 0;
      padding-bottom: 5px;
      font-weight: 500;
  }
  .qTaxesInner {
      font-size: 1rem;
  }

  .qFooterCompany {
      font-size: 18px;
      margin: 0;
      text-transform: uppercase;
      padding-bottom: 5px;
  }

  .qNotice {
      font-size: 13px;
      color: #787878;
  }

  .qBrand {
      text-transform: uppercase;
      font-weight: 500;
      margin: 0;
      padding: 0px 0px;
      font-size: 1rem;
  }

  .qBrandAddress {
      margin: 0;
      padding-bottom: 5px;
  }

  p.qNote {
      font-family: 'Montserrat';
      color: #515171;
      font-weight: 500;
      font-size: 1rem;
  }

  .qTable td {
      font-weight: 500;
  }

  td.qUppercase {
      font-weight: 600;
  }

  .qTable thead th {
      border-color: #dde2eb;
      background-color: #eff0f2;
      font-weight: 500;
      text-transform: uppercase;
      font-family: 'Lato';
  }

  .qPrice {
      font-size: 1.4rem;
      font-weight: 600;
      padding-top: 15px;
      /* color: #0561ff !important; */
  }

  .qTable {
      border: 1px solid #dde2eb;
  }

  hr.qHr {
      padding: 10px 0px;
      margin: 0;
      border-color: #b7bdc8;
      border-width: 2px;
  }

  .row.qHeader {
    border-bottom: 1px solid #edeff1;
    padding-bottom: 10px;
    margin-bottom: 35px;
}

.row.qLetter {
    font-size: .75rem;
}

p.qCustomerInfo {
    padding-top: 10%;
    font-size: 1.25rem;
}

.row.qLetter div p {
    font-weight: 500;
}

.row.qHeader {
    border-bottom: 1px solid #edeff1;
    padding-bottom: 10px;
    margin-bottom: 35px;
    margin-top: 15px;
}

.row.qCompany {
    border-top: 1px solid #edeff1;
    margin-top: 40px;
}

.row.qCompany .col-12 {
    padding-top: 20px;
}

.qTermsSection {
    padding: 0 11px;
}

  .modal-dialog.modal-lg {
    width: 100%;
    max-width: 950px;
    padding: 0 10px;
  }

  nav.bg-epi-blue.navbar.navbar-expand-lg {
      padding: 5px 20px;
  }


  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {

    .qPaddingTop {
      padding-top: 20px;
    }

    .qPaddingBottom {
      padding-bottom: 20px;
    }

    .qQuoteInfo {
        margin-bottom: 0;
    }

    .qLabel {
        font-size: 14px;
    }

    .qAddress {
        font-weight: 600;
        font-size: 16px;
        margin: 0;
    }

    .qHr {
        border-color: #D6E4FF;
    }

    .qLarge {
        font-size: 22px;
    }

    .qMedium {
        font-size: 16px;
    }

    .qPricing {
      text-align: right;
    }

  }

  @media (max-width: 767px) {

    .customBorder {
        margin-bottom: 20px;
    }

  }

#content {
  counter-reset: page; 
}

#pageCounter {
  display: table-footer-group;
}

#pageCounter::before {
  counter-increment: page;
  content: counter(page) ' of ';
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}
.highLightButton :hover {
  color: rgb(220, 53, 69);
  cursor:pointer;
}
.glow {
  color: #000;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 0px #fff,
    0 0 2px #e60073,
    0 0 0px #e60073,
    0 0 0px #e60073,
    0 0 0px #e60073,
    0 0 0px #e60073,
    0 0 0px #e60073,
  }

  to {
    text-shadow: 0 0 1px #fff,
    0 0 1px rgb(220 53 69),
    0 0 1px rgb(220 53 69),
    0 0 1px rgb(220 53 69),
    0 0 1px rgb(220 53 69),
    0 0 1px rgb(220 53 69),
    0 0 1px rgb(220 53 69);
  }
}

.liveBox {
    background-color: #0561ff;
    border-style: solid;
    margin: 1px;
    padding: 2px 5px;
    color: white;
    border-radius: 10px;
    border: 2px solid #000e29;
}

.sidebar-main {
    font-family: var(--font-family);
    /* background-color: var(--main-color); */
    width: 270px;
    position: absolute;
    right: 0;
    top: 0;
    padding-left: 10px;
}
.sidebar-main h5 {
    font-weight: 600;
    color: #4B5976;
    margin-bottom: 12px;
}

.sidebar-main p {
    margin-left: 15px;
}

.sidebar-main a {
    text-decoration: none;
    color: #949CAD;
    font-weight: 500;
}

.sidebar-main a:hover {
    color: var(--main-color);
}

.sidebar-main .icon {
    font-size: 21px;
}

.sidebar-main table {
    margin-bottom: 10px;
    margin-left: 10px;
}

.sidebar-main .first-td {
    width: 40px;
}

@media only screen and (max-width: 993px) {
    .body-main {
        grid-template-columns: 12fr !important;
        position : relative;
    }
    .bm-child-1{
        display: none;
    }
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  background-color: #FFFFFF;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 70px;
}

.sideNavBack {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  background-color: #FFFFFF;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 70px;
}

.sidenav a {
  /* padding: 8px 8px 8px 32px; */
  text-decoration: none;
  /* font-size: 17px; */
  font-weight: 600;
  color: #000;
  /* display: block; */
  transition: 0.3s;
}

.sidenav a:hover {
  color: #095CF6;
}

.sidenav .nextbtn {
  position: absolute;
  top: 0;
  right: 25px;
  color: #095CF6;
  font-size: 26px;
  margin-left: 50px;
}

.contentM {
  max-width: 500px !important;
  margin: auto !important;
  background: white;
  padding: 10px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

div.scroll {
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
}


