.pm-img {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    object-fit: cover;
}

.pw-card-img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.test-img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 50px;
    object-fit: cover;
}